@import url('https://unpkg.com/leaflet@1.4.0/dist/leaflet.css');

// @font-face {
//   font-family: "Lato";
//   src: url(./fonts/lato/lato-light.eot);
//   src: url(./fonts/lato/lato-light.eot) format("embedded-opentype"),
//   url(./fonts/lato/lato-light.woff) format("woff");
//   font-weight: 100;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lato";
//   src: url(./fonts/lato/lato-regular.eot);
//   src: url(./fonts/lato/lato-regular.eot) format("embedded-opentype"),
//   url(./fonts/lato/lato-regular.woff) format("woff");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lato";
//   src: url(./fonts/lato/lato-regular.eot);
//   src: url(./fonts/lato/lato-regular.eot) format("embedded-opentype"),
//   url(./fonts/lato/lato-regular.woff) format("woff");
//   font-weight: normal;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Lato";
//   src: url(./fonts/lato/lato-bold.eot);
//   src: url(./fonts/lato/lato-bold.eot) format("embedded-opentype"),
//   url(./fonts/lato/lato-bold.woff) format("woff");
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Akrobat";
//   src: url(./fonts/akrobat/Akrobatblack.ttf);
//   src: url(./fonts/akrobat/Akrobatblack.woff) format("woff");
//   font-weight: 800;
//   font-style: normal;
// }
@font-face {
  font-family: 'TT Hoves';
  src: url(../fonts/TT_Hoves/TT-Hoves-Light.otf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url(../fonts/TT_Hoves/TT-Hoves-Regular.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url(../fonts/TT_Hoves/TT-Hoves-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url(../fonts/TT_Hoves/TT-Hoves-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

#root {
  height: 100%;
  width: 100%;
}
:root {
  --primary: #ea0029;
  --formActive: #212529;
  --formActive-5: rgba(33, 37, 41, 0.5);
  --formPlaceholder: #9b9b9b;
  --lightGray: #dbdbdb;
  --black: #000000;
  --danger: #dc3545;
  --secondary: #2177ff;
  --success: #28a745;
  --warning: #025e39;
  --warning-text: #fff;
  --info: #ea0029;
  --white: #ffffff;
  --badge: #212428;
  --primary-5: rgba(250, 184, 30, 0.05);
  --titleForm: #000000;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  outline: 0;
  font-family: TT Hoves;
  font-variant-numeric: tabular-nums;
  line-height: 21px;
  font-size: 14px;
}

html {
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  background: #fff;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 16px;
  height: 100%;
  width: 100%;
  font-family: 'Lato', 'Arial', 'Helvetica';
}

code {
  display: block;
  max-width: 700px;
  height: auto;
  background: tomato;
  margin: auto;
  color: white;
  padding: 15px 30px;
  font-weight: bold;
  margin-top: 100px;
}

pre {
  white-space: pre-wrap;
}

img {
  width: 100%;
  height: auto;
}

.with-preloader {
  height: 500px;
  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    div {
      margin-bottom: -110px;
    }
  }
  position: relative;
  i,
  svg {
    width: 60px;
    height: 60px;
  }
  i {
    position: absolute;
    left: calc(50% - 30px);
    top: calc(50% - 30px);
  }
  @media (max-width: 768px) {
    & {
      height: 300px;
    }
  }
}

.flex {
  display: flex;
  &-center {
    justify-content: center;
    align-items: center;
  }
}

.fdc {
  flex-direction: column;
}

.aic {
  align-items: center;
}

.acc {
  align-content: center;
}

.jcsb {
  justify-content: space-between;
}

.jcsa {
  justify-content: space-around;
}

.ais {
  align-items: stretch;
}

.aifs {
  align-items: flex-start;
}

.aife {
  align-items: flex-end;
}

.aife {
  align-items: flex-end;
}

.jcc {
  justify-content: center;
}

.jcfe {
  justify-content: flex-end;
}

.App .container {
  padding: 0;
}

.App .btn {
  background: #fff;

  &:focus,
  &:active {
    outline: 0 !important;
  }
}

[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

a {
  color: initial;
  text-decoration: none;
}

.list-buttons {
  display: flex;
  flex-direction: row;
}

.button--primary {
  height: 38px;
  padding: 8px 12px;
  margin-right: 18px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  line-height: 1.43;
  letter-spacing: 0.2px;
  background: #fff;

  &:hover {
    border: solid 1px rgba(0, 0, 0, 0.35);
    color: inherit;
  }

  &:active {
    box-shadow: none;
  }
}

.button {
  &--confirm {
    color: #28a745;
    color: var(--success);
  }

  &--reject {
    color: #dc3545;
    color: var(--danger);
  }
}

.button-link {
  height: 38px;
  padding: 8px 16px;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.2px;
  font-size: 14px;
}

.icon-link {
  width: 12px;
}

.link--download {
  background-color: #fff;
  color: var(--secondary);
}

.info-arrow {
  margin-left: 8px;
  width: 18px;
  transform: rotate(270deg);
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.buttons-group-wrap {
  margin-top: 46px;
  margin-bottom: 42px;
  width: 100%;
  text-align: center;
}

.popup-button {
  margin: 0 8px;
  padding: 8px 58px;
  height: 42px;
  background-color: rgba(216, 216, 216, 0);
  border: solid 1px rgba(0, 0, 0, 0.25);
  &:hover {
    background: rgba(216, 216, 216, 0.11);
  }

  &--edit {
    padding: 0;
    width: 169px;
  }

  &--save {
    margin: 0 8px;
    width: 169px;
    height: 42px;
    background-color: #ffc107;
    background-color: var(--warning);
    color: var(--warning-text);
  }
  &--save:hover {
    --warning: var(--badge);
    color: var(--warning-text);
    /* background-color: var(--warning); */
  }
}

.ac-textarea {
  display: block;
  min-height: 100px;
  margin: 24px auto;
  resize: none;
  width: 90%;
  border-color: rgba(0, 0, 0, 0.25);
}

.ac-form-error-message {
  font-size: 14px;
  color: var(--danger);
  display: block;
  line-height: 1.4;
  margin-left: 20px;
  margin-top: -9px;
}

.button-link {
  position: relative;
  min-width: 160px;
}

.button-link .loader {
  width: 20px;
  height: 20px;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}

.button-link.is-loading span {
  display: none;
}

.button-link.is-loading::before {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  z-index: 10;
  border: 3px solid rgba(0, 0, 0, 0.5);
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.detailed-content .row.is-empty__message {
  display: none;
}

.is-empty--show + .is-empty__message {
  display: block !important;
}

.button--disabled-default {
  pointer-events: none;
  cursor: not-allowed !important;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.button--primary:active,
.btn-default:active:hover,
.button-link:active {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: white;
}

.btn-default:hover {
  border-color: #8c8c8c;
}

.popup-button--save {
  &,
  &:hover,
  &:active {
    border: none;
  }
}

.App .btn.button--primary:hover,
.App .btn.button--primary:active,
.button-link:hover,
.button-link:active {
  /* box-shadow: none; */
  border: solid 1px #8c8c8c;
}

.danger {
  border-color: #8c8c8c;
  color: var(--danger);
  &:hover {
    background: none;
    color: #8c8c8c;
  }
}

.df {
  display: flex;
}
.fww {
  flex-wrap: wrap;
}
.fdc {
  flex-direction: column;
}

.aife {
  align-items: flex-end;
}

.color-input {
  width: 50px;
  height: 50px;
  margin-bottom: 30px;
}

.btn--warning {
  background: var(--varning);
}

a:hover {
  color: var(--info);
}

a:active,
a:focus {
  color: inherit;
  outline: none;
}

.ant-time-picker-panel {
  z-index: 9999;
}

.modal-position-wrap {
  position: fixed;
  top: 0;
  left: 0;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}

.person-info {
  width: 100%;
  height: auto;
}

.container {
  width: 1144px;
  margin: 0 auto;
  margin-top: 84px;
}

.page-wrapper {
  width: 100%;
  // margin: 30px 0 0 40px;
}

.page-content {
  padding: 30px 40px 20px 40px;
  background-color: #fff;
}

.main-content,
body,
html {
  background-color: #f8f8f8 !important;
}

@media (max-width: 1280px) {
  .App .container {
    width: 100%;
    padding: 0 32px;
  }
}

@media (max-width: 900px) {
  .page-wrapper {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .page-wrapper {
    margin-top: 10px;
  }
  .App .container {
    margin: 83px auto 0;
  }
}

@media (max-width: 640px) {
  .container {
    max-width: 1100px;
  }
}

@media (max-width: 480px) {
  .person-info {
    padding: 0 10px;
  }
  .App .container {
    padding: 0 !important;
  }
}
