.FanzillaConfirmation {
  .ant-modal-content {
    border-radius: 10px !important;
    padding: 0px;
    margin-bottom: 24px;
    font-size: 14px !important;
    line-height: 21px !important;

    .ant-modal-close {
      min-width: 70px;
      min-height: 70px;
      padding: 10px;
      top: 0;
      inset-inline-end: 0px;

      svg {
        width: 50%;
        height: 50%;
      }
    }
    .ant-modal-close:hover {
      background-color: transparent;
    }
  }

  .ant-modal-close-x {
    line-height: 56px;
  }

  .ant-modal-header {
    border-radius: 10px 10px 0 0;
    position: relative;
    padding: 27px 70px 24px 32px;
    border-bottom: 0 !important;
    margin: 0;

    .ant-modal-title {
      font-weight: bold;
      font-size: 24px;
      line-height: 110%;
      letter-spacing: -0.26px;
      color: #323232;
    }
  }

  .ant-modal-body {
    padding: 10px 10px 10px 32px;
    font-family: TT Hoves;
    font-style: normal;
    font-weight: normal;
    font-size: 19.2px !important;
    line-height: 120%;
    font-weight: 500;
    padding: 0px 24px 32px;
    color: rgba(0, 0, 0, 0.65);
  }

  .ant-modal-footer {
    border-top: 0 !important;
    font-size: 19.2px !important;
    justify-content: space-between;
    padding: 21px 15px 15px 15px;
    align-items: center;
    margin-top: 0;
  }

  .ant-btn {
    background: rgba(50, 50, 50, 0.5);
    opacity: 0.9;
    border-radius: 8px;
    border: 0 !important;
    text-align: center;
    font-family: TT Hoves;
    line-height: 1.499;

    span {
      font-family: TT Hoves;
      font-style: normal;
      font-weight: 550;
      font-size: 1.38rem;
      color: #ffffff;
      line-height: 28.7808px;
    }

    border-radius: 10px;
    font-style: normal;
    min-width: 158px;
    height: 42px;
  }

  .ant-btn-dangerous {
    background: #eb5757;
  }

  .ant-btn-primary {
    font-size: 18px;
    background: #299261;
  }
}
