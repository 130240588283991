.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  &__container {
    background-color: #fff;
    width: 360px;
    height: 390px;
    text-align: center;
  }
  &__header {
    text-align: center;
    font-family: Roboto-bold;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    padding: 18px 0 70px 0;
    line-height: 36px;
  }
  &__telephone-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: rgba(0, 0, 0, 0.65);
    font-family: Roboto-regular;
    font-size: 16px;
    line-height: 25px;
    padding: 6px 12px;
    letter-spacing: 2px;
  }
  &__password-input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    color: rgba(0, 0, 0, 0.65);
    font-family: Roboto-regular;
    font-size: 16px;
    line-height: 25px;
    padding: 6px 12px;
    margin: 20px 0 0 0;
    letter-spacing: 2px;
  }
  &__proceed-button {
    margin: 20px 0 0 0;
    height: 40px;
  }
  &__go-to-reset {
    display: block;
    padding: 0;
    font-size: 12px;
    max-width: 100px;
    color: #979797;
    cursor: pointer;
    margin: 18px auto 0 auto;
    transition: all 0.2s linear;
    line-height: 18px;
    &:hover {
      transition: all 0.2s linear;
      color: #000;
    }
  }
}
