.placeholder {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
  &__back {
    background-color: var(--fan-theme_clubNavigation1);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 12px;
    opacity: 0.05;
  }
  &__image {
    width: 265px;
    height: 265px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__title {
    color: var(--fan-theme_clubNavigation1);
    font-weight: bold;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
  }
  &__text {
    color: var(--fan-theme_clubNavigation1);
    margin-top: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 110%;
    text-align: center;
  }
  &__link {
    background-color: var(--fan-theme_clubPrimary);
    color: var(--fan-theme_clubNavigation2);
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 8.5px 0;
    width: 180px;
    border-radius: 8px;
    margin-bottom: 42px;
    z-index: 2;
    &:hover {
      color: #fff;
    }
  }
}
