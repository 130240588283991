.ant-dropdown-menu-item {
  &:hover {
    background-color: #fff;
    color: #000;
  }
  &:focus {
    background-color: #fff;
    color: #000;
  }
}
.mobile {
  display: none;
}
.ticket {
  width: 100%;
  max-width: 672px;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  padding: 14px 14px 14px 14px;
  margin-bottom: 16px;
  clip-path: polygon(
    0% calc(50% - 13px),
    0.7% calc(50% - 10px),
    0.7% calc(50% + 10px),
    0% calc(50% + 13px),
    0% 100%,
    100% 100%,
    100% calc(50% + 13px),
    99.3% calc(50% + 10px),
    99.3% calc(50% - 10px),
    100% calc(50% - 13px),
    100% 0%,
    0% 0%
  );
  // &::before {
  //   position: absolute;
  //   content: '';
  //   top: calc(50% - 12px);
  //   left: -12px;
  //   height: 24px;
  //   width: 16px;
  //   border-radius: 5px 5px 5px 5px;
  //   background-color: #fff;
  //   box-shadow: inset -3px 0px 3px rgba(0, 0, 0, 0.08);
  // }
  // &::after {
  //   position: absolute;
  //   content: '';
  //   top: calc(50% - 12px);
  //   right: -12px;
  //   height: 24px;
  //   width: 16px;
  //   border-radius: 5px 5px 5px 5px;
  //   background-color: #fff;
  //   box-shadow: inset 3px 0px 3px rgba(0, 0, 0, 0.08);
  // }
  &__menu-button {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #febc11;
    cursor: pointer;
    &:hover {
      background-color: #febc11 !important;
      border-color: #febc11;
    }
    &:focus {
      background-color: #febc11 !important;
      border-color: #febc11;
    }
  }
  &__season-container {
    display: flex;
    align-items: center;
    &__icon {
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px;
    }
    &__title {
      font-weight: bold;
      margin-left: 6px;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #989898;
    }
  }
  &__title-container {
    margin-top: 8px;
    display: flex;
    &__first {
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      color: #299261;
    }
    &__vs {
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      color: #a2a2a2;
    }
    &__second {
      font-weight: 800;
      font-size: 22px;
      line-height: 26px;
      color: #000;
    }
  }

  &__info-container {
    margin-top: 13px;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 12px !important;
    line-height: 18px !important;
    text-transform: uppercase;
    &__icon {
      height: 16px;
      width: 16px;
      background-image: url('../../../../images/calendar.svg');
      background-repeat: no-repeat;
      margin-right: 6px;
    }
    &__day {
      color: #299261;
      font-size: 12px !important;
      line-height: 18px !important;
    }
    &__date {
      color: #323232;
      font-size: 12px !important;
      line-height: 18px !important;
    }
    &__msk {
      color: #323232;
    }
    &__location {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      text-transform: uppercase;
      color: #989898;
    }
  }
  &__tickets-container {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    &__pagination {
      display: flex;
      justify-content: center;
      margin-top: 6px;
      width: 100%;
      border-radius: 8px;

      a {
        line-height: unset;
      }

      span {
        line-height: 0;
      }
    }

    .ant-pagination-item {
      border-radius: 8px;

      &-container {
        line-height: inherit;
      }

      &-ellipsis {
        line-height: unset;
      }
    }
  }
  &__logo-container {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 73px;
    height: 73px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &__bg {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 73px;
      height: 73px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(./bg.png);
    }
  }
  &__long-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    max-width: 581px;
    height: 35px;
    border: 1px solid rgba(189, 189, 189, 0.5);
    margin-top: 6px;
    cursor: pointer;
    &__icon {
      height: 18px;
      width: 18px;
      background-repeat: no-repeat;
      background-image: url('../../../../images/more.svg');
    }
    &__text {
      margin-left: 7px;
      font-weight: normal;
      font-size: 12px;
      color: #323232;
      line-height: 18px;
    }
  }
}

@media (max-width: 840px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inherit;
  }
  .ticket {
    &__title-container {
      flex-direction: column;
      &__second {
        display: flex;
      }
    }
    &__tickets-container {
      // width: 268px;
      flex-direction: column;
      justify-content: center;
    }
    &__long-button {
      width: 100%;
      max-width: 280px;
    }
  }
}
