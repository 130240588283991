body, html {
    width: 100%;
    height: 100%;
}

body {
    font-family: 'Roboto', sans-serif !important;
    margin: 0;
    padding: 0;
}
  
img {
    width: 100%;
    height: auto;
}

.appearance {
    animation: pulse .5s linear;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
