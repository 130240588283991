:root {
  --fan-theme_clubBg1: var(--fan-theme_clubBg1-override, rgba(104, 60, 147, 1));
  --fan-theme_clubBg2: var(
    --fan-theme_clubBg2-override,
    rgba(236, 233, 224, 1)
  );
  --fan-theme_clubBg3: var(--fan-theme_clubBg3-override, rgba(55, 24, 85, 1));
  --fan-theme_clubNavigation1: var(
    --fan-theme_clubNavigation1-override,
    rgba(51, 51, 51, 1)
  );
  --fan-theme_clubNavigation2: var(
    --fan-theme_clubNavigation2-override,
    rgba(255, 255, 255, 1)
  );
  --fan-theme_clubPrimary: var(
    --fan-theme_clubPrimary-override,
    rgba(129, 59, 195, 1)
  );
  --fan-theme_clubPrimaryLight: var(
    --fan-theme_clubPrimaryLight-override,
    rgba(172, 110, 231, 1)
  );
  --system_Black: rgba(50, 50, 50, 1);
  --system_Black05: rgba(50, 50, 50, 0.05);
  --system_Black10: rgba(50, 50, 50, 0.1);
  --system_Black25: rgba(50, 50, 50, 0.25);
  --system_Black50: rgba(50, 50, 50, 0.5);
  --system_Green: rgba(39, 174, 96, 1);
  --system_Orange: rgba(255, 149, 0, 1);
  --system_PureBlack: rgba(0, 0, 0, 1);
  --system_Red: rgba(235, 87, 87, 1);
  --system_systemBlack: rgba(50, 50, 50, 1);
  --system_systemBlack50: rgba(50, 50, 50, 1);
  --system_systemGreen: rgba(39, 174, 96, 1);
  --system_systemWhite: rgba(255, 255, 255, 1);
  --system_White: rgba(255, 255, 255, 1);
  --system_White50: rgba(255, 255, 255, 0.5);
  --system_Yellow: rgba(242, 201, 76, 1);
}
